/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ index, follow, description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `google-site-verification`,
          content: `Q5JmPKqCzmv-x2pbLgdHZohtB20dUk7Yy29pCF-HFsE`
        },
        {
          name: `robots`,
          content: `${index ? `index` : `noindex`}, ${follow ? `follow` : `nofollow`}`
        }
      ].concat(meta)}
      script={[
        {
          type: `text/javascript`,
          defer: `false`,
          id: `intellimizeCode`,
          innerHTML: `
          <!-- Intellimize start --> <style>.anti-flicker, .anti-flicker * {visibility: hidden !important; opacity: 0 !important;}</style> <script>(function(e,t,p){var n=document.documentElement,s={p:[],r:[]},u={p:s.p,r:s.r,push:function(e){s.p.push(e)},ready:function(e){s.r.push(e)}};e.intellimize=u,n.className+=" "+p,setTimeout(function(){n.className=n.className.replace(RegExp(" ?"+p),"")},t)})(window, 4000, 'anti-flicker')</script> <link rel="preload" href="https://cdn.intellimize.co/snippet/117539440.js" as="script"> <script src="https://cdn.intellimize.co/snippet/117539440.js" async onerror="document.documentElement.className = document.documentElement.className.replace(RegExp(' ?anti-flicker'), '');"></script> <link rel="preconnect" href="https://api.intellimize.co" crossorigin> <link rel="preconnect" href="https://117539440.intellimizeio.com"> <link rel="preconnect" href="https://log.intellimize.co" crossorigin> <!-- Intellimize end -->
          `
        },
        {
          type: `text/javascript`,
          defer: `false`,
          innerHTML: `
          console.log('page loaded in:' + (window.performance.timing.navigationStart - window.performance.timing.domContentLoadedEventEnd) + 'ms');
          let startMeasureTime = Date.now();
          let smallTest = setInterval(() => {
            if(document.querySelector('.spz-class') && document.querySelectorAll('.spz-class').length === 6){
              clearInterval(smallTest)
              let endTime = Date.now();
              let timeSpan = endTime - startMeasureTime;
              console.log('Small Experiment loaded in:'+timeSpan+'ms');
            }
          }, 1);
          let bigTest = setInterval(() => {
            if(document.querySelector('.dev-stack')){
              clearInterval(bigTest)
              let endTime = Date.now();
              let timeSpan = endTime - startMeasureTime;
              console.log('Big Experiment loaded in:'+timeSpan+'ms');
            }
          }, 1);
          `
        }
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
